<div fxFlexFill fxLayout="column" fxLayoutAlign="center center">

  <div fxLayout="column">
    
    <h1 class="mat-headline">Digital Tool Builders</h1>
    
    <div>

      <router-outlet></router-outlet>

    </div>

  </div>

</div>

